const initialState = {
    file: false
};

const fileReducer = (state = initialState, action) => {

    switch (action.type) {
        case 'SET_FILE':
            state.file = action.payload
            return { file: state.file }
        default:
            return state;
    }

}

export default fileReducer;