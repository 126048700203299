const initialState = {
    payer:{}
};

const payerReducer = (state = initialState ,action) => {

    switch(action.type) {
        case 'SET_PAYER' :
            return {...state.payer,...action.payload}
        default :
            return state;
    }

}

export default payerReducer;