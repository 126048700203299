import React from "react";
import { RoutePath, AdminRoutePath } from "./path";

/*===================--------Import Employer/Jobseeker Account Pages--------======================*/
const HomePage = React.lazy(() =>
  import("../../pages/account/HomePage")
);
const ProfilePage = React.lazy(() =>
  import("../../pages/account/ProfilePage")
);
const EditProfilePage = React.lazy(() =>
  import("../../pages/account/EditProfilePage")
);
const CvApprovelPage = React.lazy(() =>
  import("../../pages/account/CvApprovelPage")
);
const SettingPage = React.lazy(() =>
  import("../../pages/account/SettingPage")
);
const AvailablePositionsPage = React.lazy(() =>
  import("../../pages/account/employers/position/AvailablePositionsPage")
);
const PositionDetailsPage = React.lazy(() =>
  import("../../pages/account/employers/position/PositionDetailsPage")
);
const PositionEditPage = React.lazy(() =>
  import("../../pages/account/employers/position/PositionEditPage")
);
const ManagePositionsPage = React.lazy(() =>
  import("../../pages/account/employers/position/ManagePositionsPage")
);
const UploadPositionPage = React.lazy(() =>
  import("../../pages/account/employers/position/UploadPositionPage")
);
const AvailableApplicantsPage = React.lazy(() =>
  import("../../pages/account/applicants/AvailableApplicantsPage")
);
const FavouritePositionsPage = React.lazy(() =>
  import("../../pages/account/applicants/position/FavouritePositionsPage")
);
const MatchedPositionsPage = React.lazy(() =>
  import("../../pages/account/applicants/position/MatchedPositionsPage")
);
const ManageApplicationsPage = React.lazy(() =>
  import("../../pages/account/applicants/position/ManageApplicationsPage")
);
const ApplicantDetailsPage = React.lazy(() =>
  import("../../pages/account/applicants/ApplicantDetailsPage")
);
const PendingPage = React.lazy(() =>
  import("../../pages/account/PendingPage")
);
const DeactivatedPage = React.lazy(() =>
  import("../../pages/account/DeactivatedPage")
);

/*===================--------Import Admin Account Pages--------======================*/
const AdminHomePage = React.lazy(() =>
  import("../../pages/account/admin/HomePage")
);
const AdminProfilePage = React.lazy(() =>
  import("../../pages/account/admin/ProfilePage")
);
const AdminEditProfilePage = React.lazy(() =>
  import("../../pages/account/admin/EditProfilePage")
);
const AdminSettingPage = React.lazy(() =>
  import("../../pages/account/admin/SettingPage")
);
const AdminManageEmployerPage = React.lazy(() =>
  import("../../pages/account/admin/employers/ManageEmployerPage")
);
const AdminManageApplicantPage = React.lazy(() =>
  import("../../pages/account/admin/applicants/ManageApplicantPage")
);
const AdminManagePositionPage = React.lazy(() =>
  import("../../pages/account/admin/positions/ManagePositionPage")
);
const AdminEmployerDetailsPage = React.lazy(() =>
  import("../../pages/account/admin/employers/EmployerDetailsPage")
);
const AdminApplicantDetailsPage = React.lazy(() =>
  import("../../pages/account/admin/applicants/ApplicantDetailsPage")
);
const AdminPositionDetailsPage = React.lazy(() =>
  import("../../pages/account/admin/positions/PositionDetailsPage")
);
const AdminEmployerEditPage = React.lazy(() =>
  import("../../pages/account/admin/employers/EmployerEditPage")
);
const AdminApplicantEditPage = React.lazy(() =>
  import("../../pages/account/admin/applicants/ApplicantEditPage")
);
const AdminPositionEditPage = React.lazy(() =>
  import("../../pages/account/admin/positions/PositionEditPage")
);
const AdminManageInstitutePage = React.lazy(() =>
  import("../../pages/account/admin/institutes/ManageInstitutePage")
);
const AdminManageQualificationPage = React.lazy(() =>
  import("../../pages/account/admin/qualifications/ManageQualificationPage")
);
const AdminManageJobIndustriesPage = React.lazy(() =>
  import("../../pages/account/admin/job-industries/ManageJobIndustriesPage")
);
const AdminManageSkillsPage = React.lazy(() =>
  import("../../pages/account/admin/skills/AdminManageSkillsPage")
);
const AdminManageCountriesPage = React.lazy(() =>
  import("../../pages/account/admin/countries/ManageCountriesPage")
);
const AdminManageProvincePage = React.lazy(() =>
  import("../../pages/account/admin/province/ManageProvincePage")
);
const AdminManageCitiesPage = React.lazy(() =>
  import("../../pages/account/admin/cities/ManageCitiesPage")
);
const AdminManageEmailTemplatesPage = React.lazy(() =>
  import("../../pages/account/admin/email-templates/ManageEmailTemplatesPage")
);

const privateRoutes = [
  { path: RoutePath.HOME, name: "Dashboard | Recruit Graduates", component: HomePage },
  { path: RoutePath.PROFILE, name: "Profile details | Recruit Graduates", component: ProfilePage },
  { path: RoutePath.EDIT_PROFILE, name: "Edit Profile details | Recruit Graduates", component: EditProfilePage },
  { path: RoutePath.CV_APPROVEL, name: "CV Approvel Process | Recruit Graduates", component: CvApprovelPage },
  { path: RoutePath.SETTING, name: "Setting | Recruit Graduates", component: SettingPage },
  { path: RoutePath.POSITIONS, name: "All Jobs | Recruit Graduates", component: AvailablePositionsPage },
  { path: RoutePath.FAVOURITE_POSITIONS, name: "Favourite Jobs | Recruit Graduates", component: FavouritePositionsPage },
  { path: RoutePath.MATCHED_POSITIONS, name: "Matched Jobs | Recruit Graduates", component: MatchedPositionsPage },
  { path: RoutePath.MANAGE_APPLICATIONS, name: "Manage Applications | Recruit Graduates", component: ManageApplicationsPage },
  { path: RoutePath.POSITION_DETAILS, name: "Job details | Recruit Graduates", component: PositionDetailsPage },
  { path: RoutePath.APPLICANT_DETAILS, name: "Jobseeker details | Recruit Graduates", component: ApplicantDetailsPage },
  { path: RoutePath.POSITION_EDIT, name: "Edit Job | Recruit Graduates", component: PositionEditPage },
  { path: RoutePath.MANAGE_POSITIONS, name: "Manage Jobs | Recruit Graduates", component: ManagePositionsPage },
  { path: RoutePath.UPLOAD_A_POSITION, name: "Post Job | Recruit Graduates", component: UploadPositionPage },
  { path: RoutePath.APPLICANTS, name: "Available Applicants | Recruit Graduates", component: AvailableApplicantsPage },
  { path: RoutePath.PENDING_PAGE, name: "Pending | Recruit Graduates", component: PendingPage },
  { path: RoutePath.DEACTIVATED_PAGE, name: "Deactivated | Recruit Graduates", component: DeactivatedPage },

  { path: AdminRoutePath.HOME, name: "Dashboard | Recruit Graduates", component: AdminHomePage },
  { path: AdminRoutePath.PROFILE, name: "Profile details | Recruit Graduates", component: AdminProfilePage },
  { path: AdminRoutePath.EDIT_PROFILE, name: "Edit Profile details | Recruit Graduates", component: AdminEditProfilePage },
  { path: AdminRoutePath.SETTING, name: "Setting | Recruit Graduates", component: AdminSettingPage },
  { path: AdminRoutePath.MANAGE_EMPLOYERS, name: "List Of Employers | Recruit Graduates", component: AdminManageEmployerPage },
  { path: AdminRoutePath.MANAGE_APPLICANTS, name: "List Of Applicants | Recruit Graduates", component: AdminManageApplicantPage },
  { path: AdminRoutePath.MANAGE_POSITIONS, name: "List Of Jobs | Recruit Graduates", component: AdminManagePositionPage },
  { path: AdminRoutePath.EMPLOYER_DETAILS, name: "Employer details | Recruit Graduates", component: AdminEmployerDetailsPage },
  { path: AdminRoutePath.APPLICANT_DETAILS, name: "Jobseeker details | Recruit Graduates", component: AdminApplicantDetailsPage },
  { path: AdminRoutePath.POSITION_DETAILS, name: "Job details | Recruit Graduates", component: AdminPositionDetailsPage },
  { path: AdminRoutePath.EMPLOYER_EDIT, name: "Edit Employer | Recruit Graduates", component: AdminEmployerEditPage },
  { path: AdminRoutePath.APPLICANT_EDIT, name: "Edit Jobseeker | Recruit Graduates", component: AdminApplicantEditPage },
  { path: AdminRoutePath.POSITION_EDIT, name: "Edit Job | Recruit Graduates", component: AdminPositionEditPage },
  { path: AdminRoutePath.MANAGE_INSTITUTES, name: "List Of Institutes | Recruit Graduates", component: AdminManageInstitutePage },
  { path: AdminRoutePath.MANAGE_QUALIFICATIONS, name: "List Of Qualifications | Recruit Graduates", component: AdminManageQualificationPage },
  { path: AdminRoutePath.MANAGE_JOB_INDUSTRIES, name: "List Of Job Industries | Recruit Graduates", component: AdminManageJobIndustriesPage },
  { path: AdminRoutePath.MANAGE_JOB_SKILLS, name: "List Of Skills | Recruit Graduates", component: AdminManageSkillsPage },
  { path: AdminRoutePath.MANAGE_COUNTRIES, name: "List Of Countries | Recruit Graduates", component: AdminManageCountriesPage },
  { path: AdminRoutePath.MANAGE_PROVINCE, name: "List Of Province | Recruit Graduates", component: AdminManageProvincePage },
  { path: AdminRoutePath.MANAGE_CITIES, name: "List Of Cities | Recruit Graduates", component: AdminManageCitiesPage },
  { path: AdminRoutePath.MANAGE_EMAIL_TEMPLATES, name: "List Of Email templates | Recruit Graduates", component: AdminManageEmailTemplatesPage }
];

export default privateRoutes;
