import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// Routes
import webRoutes from "./routes/web";
import publicRoutes from "./routes/public";
import privateRoutes from "./routes/private";

// NotFound Page
import NotFoundPage from "./pages/NotFoundPage";

// Common Containers
const WebLayout = React.lazy(() => import("./containers/webLayout"));
const PublicLayout = React.lazy(() => import("./containers/publicLayout"));
const PrivateLayout = React.lazy(() => import("./containers/privateLayout"));

const loading = () => (
  <></>
);

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            {webRoutes.map((webR, idx) => {
              return webR.component ? (
                <WebLayout
                  key={idx}
                  path={webR.path}
                  exact
                  component={webR.component}
                />
              ) : null;
            })}
            {publicRoutes.map((publicR, idx) => {
              return publicR.component ? (
                <PublicLayout
                  key={idx}
                  path={publicR.path}
                  exact
                  component={publicR.component}
                />
              ) : null;
            })}
            {privateRoutes.map((privateR, idx) => {
              return privateR.component ? (
                <PrivateLayout
                  key={idx}
                  path={privateR.path}
                  exact
                  component={privateR.component}
                />
              ) : null;
            })}
            <Route path='*' exact={true} component={ NotFoundPage } />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
