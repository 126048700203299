const initialState = {
    widget: {}
};

const widgetReducer = (state = initialState ,action) => {

    switch(action.type) {

        case 'SET_WIDGET' : 
        return {...state.widget, ...action.payload}
        case 'UNSET_WIDGET' :
        return state.widget = {}
        default :
        return state;
    }

}

export default widgetReducer;