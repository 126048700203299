import userReducer from "./reducers/user";
import payerReducer from "./reducers/payer";
import homeTabReducer from "./reducers/homeTab";
import fileReducer from "./reducers/file";
import positionReducer from "./reducers/position";
import applicantReducer from "./reducers/applicant";
import { combineReducers } from "redux";
import notificationReducer from "./reducers/notification";
import widgetReducer from "./reducers/widget";

const rootReducer = combineReducers({
  user: userReducer,
  payer: payerReducer,
  tabs: homeTabReducer,
  file: fileReducer,
  position: positionReducer,
  applicant: applicantReducer,
  notification: notificationReducer,
  widget: widgetReducer
});

export default rootReducer;
