import React from "react";
import { RoutePath } from "./path";

const MainPage = React.lazy(() =>
  import("../../pages/web/MainPage")
);
const PositionPage = React.lazy(() =>
  import("../../pages/web/PositionPage")
);
const ApplicantPage = React.lazy(() =>
  import("../../pages/web/ApplicantPage")
);
const AboutUsPage = React.lazy(() =>
  import("../../pages/web/AboutUsPage")
);
const ContactUsPage = React.lazy(() =>
  import("../../pages/web/ContactUsPage")
);
const PrivacyPolicyPage = React.lazy(() =>
  import("../../pages/web/PrivacyPolicyPage")
);
const TermsConditionPage = React.lazy(() =>
  import("../../pages/web/TermsConditionPage")
);

const webRoutes = [
  { path: RoutePath.INDEX, name: "Home | Recruit Graduates", component: MainPage },
  { path: RoutePath.MAININDEX, name: "Home | Recruit Graduates", component: MainPage },
  { path: RoutePath.PUBLIC_POSITIONS, name: "Jobs | Recruit Graduates", component: PositionPage },
  { path: RoutePath.PUBLIC_APPLICANTS, name: "Applicants | Recruit Graduates", component: ApplicantPage },
  { path: RoutePath.ABOUT_US, name: "About Us | Recruit Graduates", component: AboutUsPage },
  { path: RoutePath.CONTACT_US, name: "Contact Us | Recruit Graduates", component: ContactUsPage },
  { path: RoutePath.PRIVACY_POLICY, name: "Privacy Policy | Recruit Graduates", component: PrivacyPolicyPage },
  { path: RoutePath.TERMS_CONDITIONS, name: "Terms & Conditions | Recruit Graduates", component: TermsConditionPage }
];

export default webRoutes;
