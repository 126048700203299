import BackgroundImage1 from "../assets/public/images/page-banner.jpg";
import BackgroundImage2 from "../assets/public/images/banner-content-bg.png";
import BackgroundImage3 from "../assets/public/images/join-bg.jpg";
import loginBackground from "../assets/public/images/join-bg.jpg";

export const BackgroundStyle = {
  img1: {
    backgroundImage: `url(${BackgroundImage1})`,
  },
  img2: {
    backgroundImage: `url(${BackgroundImage2})`,
  },
  img3: {
    backgroundImage: `url(${BackgroundImage3})`,
  },
  loginBack: {
    backgroundImage: `url(${loginBackground})`,
  }
};

export const ExploreBoxStyle = {
  box1: {
    backgroundColor: '#24348b',
    color: '#fff'
  },
  box2: {
    backgroundColor: '#37b9c8',
    color: '#fff'
  },
  box3: {
    backgroundColor: '#db5959',
    color: '#fff'
  },
  box4: {
    backgroundColor: '#00056a',
    color: '#fff'
  },
  box5: {
    backgroundColor: '#f5d670',
    color: '#00056a'
  },
  box6: {
    backgroundColor: '#e7e7ea',
    color: '#00056a'
  }
};