const initialState = {
  tab: localStorage.getItem("tab") === null ? 1 : localStorage.getItem("tab"),
};

const tabvalue = {
  tab: 1,
};

const homeTabReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TAB":
      return { ...state.tabs, ...tabvalue };
    case "UNSET_TAB":
      return { ...state.tabs, ...tabvalue };
    case "SELECTED_TAB":
      localStorage.setItem("tab", action.payload.tab);
      return { ...state.tabs, ...action.payload };
    default:
      return state;
  }
};

export default homeTabReducer;
