const initialState = {
  notificationcount: 0,
  selectednotification: {},
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_NOTIFICATION_COUNT":
      state.notificationcount = action.payload;
      return {
        notificationcount: state.notificationcount,
        selectednotification: state.selectednotification,
      };
    case "SET_UNREAD_NOTIFICATION_COUNT":
      state.notificationcount = action.payload;
      return {
        notificationcount: state.notificationcount
      };
    case "UNSET_UNREAD_NOTIFICATION_COUNT":
      return {
        notificationcount: 0
      };
    case "SET_SELECTED_NOTIFICATION":
      state.selectednotification[action.payload[0]] = action.payload[0];
      return {
        notificationcount: state.notificationcount,
        selectednotification: state.selectednotification,
      };
    default:
      return state;
  }
};

export default notificationReducer;
